<template>
  <div>
    <v-row>
      <v-col lg="12" md="12" sm="12" xs="12">
        <s-crud
          sortable
          :config="this.config"
          @save="saveDef($event)"
          add
          edit
          remove
          title="Menús"
          ref="crud"
          searchInput
        >
          <template scope="props">
            <div v-if="props.item != null">
              <v-row justify="center">
                <v-col lg="3" class="s-col-form">
                  <s-text
                    autofocus
                    v-model="props.item.MntName"
                    label="Nombre de Menú"
                  ></s-text>
                </v-col>

                <v-col lg="3" class="s-col-form">
                  <s-text
                    v-model="props.item.MntAbbreviature"
                    label="Abreviatura"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="3" class="s-col-form">
                  <s-date
                    autofocus
                    v-model="props.item.MntValidityDate"
                    label="Inicio"
                  ></s-date>
                </v-col>
                <v-col lg="3" class="s-col-form">
                  <s-text
                    autofocus
                    v-model="props.item.MntDescription"
                    label="Descripción"
                  ></s-text>
                </v-col>
              </v-row>
            </div>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SToolbarPerson from "./../../components/Utils/Person/sToolbarPerson";
import Service from "./../../services/DinningHall/MenuTypeService";
export default {
  name: "MenuType",
  components: { SToolbarPerson },
  data: () => ({
    persona: null,
    numberDocument: "48711018",
    search: "",
    menu2: false,
    person: "",
    config: {
      model: {
        MntName: "string",
        MntID: "ID",
        SecStatus: "boleam",
        MntIsPrincipal: "boleam",
        MntValidityDate: "date",
        MntDescription: "string",
        MntAbbreviature: "string",
      },
      title: "MENÚS COMEDOR",
      service: Service,
      headers: [
        { text: "ID", value: "MntID", sortable: true },
        { text: "MENÚ", value: "MntName", sortable: true },
        { text: "ABREVIATURA", value: "MntAbbreviature", sortable: false },
        { text: "DESCRIPCIÓN", value: "MntDescription", sortable: false },
        { text: "FECHA DE INICIO", value: "MntValidityDate", sortable: false },
      ],
    },
  }),
  methods: {
    getPerson(event, item) {
      // value = value || {};
      if (event != null) item.MntDescription = event.NtpName || "";
      else item.MntDescription = "";
    },
    emitSave() {
      this.$refs.crud.save();
    },
    saveDef(item) {
      item.MntName = item.MntName.toUpperCase();
      if (item.MntName.trim().length < 1) {
        this.$fun.alert("Nombre Inválido", "warning");
        return;
      }
      item.save();
    },
  },
};
</script>
